<template>
	<div>
		<v-layout class="flex-wrap">
			<v-spacer></v-spacer>
			<v-flex class="py-2 my-auto d-flex">
				<v-spacer></v-spacer>
				<v-btn
					depressed
					:disabled="pageLoading"
					color="blue darken-4"
					class="text-white ml-2"
					tile
					v-on:click="advanceSearch = true"
				>
					<v-icon>mdi-filter</v-icon>
				</v-btn>
				<v-btn
					depressed
					:disabled="pageLoading"
					color="blue darken-4"
					class="text-white ml-2"
					tile
					v-on:click="advanceSearch = true"
				>
					<v-icon>mdi-clock-outline</v-icon>
				</v-btn>
				<v-menu
					bottom
					left
					origin="center center"
					transition="slide-y-transition"
					rounded="0"
					offset-y
					max-height="400px"
					:close-on-content-click="false"
					content-class="white-background"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							color="blue darken-4"
							class="text-white ml-2"
							tile
							:disabled="pageLoading"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon>mdi-menu</v-icon>
						</v-btn>
					</template>
					<v-list class="py-0">
						<template v-for="(item, i) in listingAction">
							<v-list-item link :key="i" v-on:click="doAction(item.value)">
								<v-list-item-title
									><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
									>{{ item.title }}</v-list-item-title
								>
							</v-list-item>
							<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
						</template>
					</v-list>
				</v-menu>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	name: "GanttChart",
	data() {
		return {};
	},
};
</script>
